import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Bold, Italic, Strikethrough, Underline, Code } from '@ckeditor/ckeditor5-basic-styles';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { AutoLink, Link } from '@ckeditor/ckeditor5-link';
import { Mention } from '@ckeditor/ckeditor5-mention';
import { Font } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { Image, ImageStyle, ImageToolbar, ImageUpload, ImageResize } from '@ckeditor/ckeditor5-image';
import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import { Undo } from '@ckeditor/ckeditor5-undo';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { DocumentList, DocumentListProperties } from '@ckeditor/ckeditor5-list';
import AdjacentListsSupport from '@ckeditor/ckeditor5-list/src/documentlist/adjacentlistssupport.js';

window.editors = {};

function createEditor(listener) {
    ClassicEditor.create(document.querySelector(listener), {
        // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
        plugins: [ Essentials, Paragraph, Bold, Italic, Strikethrough, Underline, Code, RemoveFormat,
            AutoLink, Link, Autoformat, Mention, Font, Heading, HorizontalLine, Undo, Alignment,
            Image, ImageStyle, ImageToolbar, ImageUpload, SimpleUploadAdapter, ImageResize,
            PasteFromOffice, BlockQuote, DocumentList, AdjacentListsSupport, DocumentListProperties ],
        toolbar: {
            items: [
                'undo', 'redo', '|',
                'bold', 'italic', 'underline', {
                    label: 'More text styling',
                    icon: 'threeVerticalDots',
                    items: ['strikethrough','fontSize', 'fontColor'],
                }, '|',
                'heading', 'alignment', 'bulletedList', 'numberedList', '|',
                'removeFormat', '|',
                'uploadImage', 'link', 'code'
            ],
            shouldNotGroupWhenFull: true
        },
        // Changing the language of the interface requires loading the language file using the <script> tag.
        // language: 'es',
        mention: {
            dropdownLimit: 5,
            feeds: [
                {
                    marker: '@',
                    feed: getUsernames,
                    minimumCharacters: 1,
                }
            ]
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
            }
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading2', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading4' },
            ]
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
        placeholder: 'Write your thoughts here...',
        // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
        fontSize: {
            options: [ 10, 12, 14, 'default', 18, 20, 22 ],
            supportAllValues: true
        },
        fontColor: {
            colorPicker: {
                format: "hex"
            }
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
        link: {
            decorators: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
                /* toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                } */
            }
        },
        image: {
            toolbar: [
                'imageStyle:block',
                'imageStyle:side',
                'imageStyle:inline',
            ]
        },
        simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: 'https://tigerthoughts.xyz/media/tigerthoughts/upload',

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]').value,
            }
        },
        alignment: {
            options: [ 'left', 'center', 'right', 'justify' ]
        },
    })
    .then(editor => {
        window.editors[listener.substring(1)] = editor;
    })
    .catch(error => {
        console.log(error);
    });
}

createEditor("#editor")
createEditor("#editor2")
